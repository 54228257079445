import axios from '../axios';

export const all = (params = {}) => {
  return axios.get('/technicians', params);
};

export const show = (id, params = {}) => {
  return axios.get(`/technicians/${id}`, params);
};

export const create = (payload) => {
  return axios.post('/technicians', payload);
};

export const update = (id, payload) => {
  return axios.put(`/technicians/${id}`, payload);
};

export const destroy = (id) => {
  return axios.delete(`/technicians/${id}`);
};

export const importTechnicians = (payload) => {
  return axios.post('/technicians/import', payload);
};
