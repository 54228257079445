export const all = () => {
  return [
    {
      key: 'county.lower_silesia',
      value: 'Dolnośląskie',
    },
    {
      key: 'county.kuyavia_pomerania',
      value: 'Kujawsko-pomorskie',
    },
    {
      key: 'county.lublin',
      value: 'Lubelskie',
    },
    {
      key: 'county.lubusz',
      value: 'Lubuskie',
    },
    {
      key: 'county.lodzkie',
      value: 'Łódzkie',
    },
    {
      key: 'county.lesser_poland',
      value: 'Małopolskie',
    },
    {
      key: 'county.masovia',
      value: 'Mazowieckie',
    },
    {
      key: 'county.opole',
      value: 'Opolskie',
    },
    {
      key: 'county.subcarpathia',
      value: 'Podkarpackie',
    },
    {
      key: 'county.podlaskie',
      value: 'Podlaskie',
    },
    {
      key: 'county.pomerania',
      value: 'Pomorskie',
    },
    {
      key: 'county.silesia',
      value: 'Śląskie',
    },
    {
      key: 'county.holy_cross',
      value: 'Świętokrzyskie',
    },
    {
      key: 'county.warmia_masuria',
      value: 'Warmińsko-mazurskie',
    },
    {
      key: 'county.greater_poland',
      value: 'Wielkopolskie',
    },
    {
      key: 'county.west_pomerania',
      value: 'Zachodniopomorskie',
    },
  ];
};

export const getCountyByKey = (key) => {
  return all().find((county) => county.key === key)?.value;
};
